<script>
import Vue from "vue";
import api from '@/helpers/apirest/api';
import CardApplicationChangeStatusForm from '@/views/pages/card-applications/forms/card-application-change-status-form.vue';
export default {
    props: {
        cardApplication: {
            type: Object,
            default: () => {},
        },
    },
    data(){
        return {
            formModalId: `card-application-form-modal-${Math.random().toString(36)}`,
        }
    },
    components:{
        CardApplicationChangeStatusForm
    },
    emits: ['onSuccess'],
    computed: {
        wasPaid() {
            return this?.cardApplication?.was_paid ? 'Marcar como NO pagado' : 'Marcar como pagado';
        }
    },
    methods: {
        changeStatus() {
            this.$bvModal.show(this.formModalId);
        },
        async changePaymentStatus() {
            const result = await Vue.swal({
                title: "Cambiar estado de pago",
                icon: "info",
                html: `Está seguro que desea cambiar el estado del pago para esta solicitud?`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar',
            });

            if (result.value) {
                await api.patch({
                    url: `card-applications/${this.cardApplication?.id}/change-payment-status`,
                    data: {
                        was_paid: !this.cardApplication?.was_paid                      
                    },
                    config: {
                        withLoading: true,
                    },
                });

                this.$emit('onSuccess');
            }
            },
        onSuccess(){
            this.$emit('onSuccess');
        }
    }
};
</script>

<template>
    <div>
        <CardApplicationChangeStatusForm :modal-id="formModalId" :card-application="cardApplication" @onSuccess="onSuccess"></CardApplicationChangeStatusForm>
        <b-dropdown no-flip right class="qlk-dropdown-sm" variant="white" size="sm" title="Más opciones" v-b-tooltip.hover>
            <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
            </template>
            <b-dropdown-item @click="changeStatus">Cambiar estado</b-dropdown-item>
            <b-dropdown-item @click="changePaymentStatus">{{wasPaid}}</b-dropdown-item>
        </b-dropdown>
    </div>    
</template>