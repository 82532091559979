<script>

/**
 * Customers Component
 */
export default {
    props: {
        customer: {
            type: Object,
            default: () => {}
        },
    },
    components: {
    },
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>

<template>
    <div class="youth-card-container d-flex">

        <div class="card h-100 mr-3" v-for="youthCard in customer?.youth_cards" :key="youthCard.id">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-dropdown right class="qlk-dropdown-sm" variant="white" size="sm">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                </template>
                <b-dropdown-item @click="showEditModal(integration)">Inactivar</b-dropdown-item>
              </b-dropdown> -->
            </div>

            <div class="youth-card-classic" v-if="youthCard?.type === 'classic'" :class="{'inactive': youthCard?.status === 'inactive'}">
                <img src="@/assets/images/youth-cards/classic.png" alt="Carné clásico" />
                <div class="youth-card-name">{{customer?.name}}</div>
                <div class="youth-card-expires">{{youthCard?.expiration_date_formatted}}</div>
                <div class="youth-card-number">{{youthCard?.code}}</div>
                <div class="youth-card-birthdate">{{customer?.birthdate_formatted}}</div>
            </div>

            <div class="youth-card-financial" v-if="youthCard?.type === 'financial'" :class="{'inactive': youthCard?.status === 'inactive'}">
                <img src="@/assets/images/youth-cards/financial.png" alt="Carné financiero" />
                <div class="youth-card-name">{{customer?.name}}</div>
                <div class="youth-card-expires">{{youthCard?.expiration_date_formatted}}</div>
                <div class="youth-card-number">{{youthCard?.code}}</div>
                <div class="youth-card-birthdate">{{customer?.birthdate_formatted}}</div>
            </div>

          </div>
        </div>
        
    </div>
</template>