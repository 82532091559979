<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import Status from "@/helpers/general/status";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";

import {
    identificationTypeComputed,
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        customer: {
            id: null,
            identification_type_id: '',
            identification_media: null,
            population_id: null,
            first_name: null,
            last_name: null,
            identification: null,
            birthdate: null,
            gender: '',
            postcode: null,
            address: null,
            email: null,
            mobile: null,
            phone: null,
            allow_commercial_mail: null,
        }
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        SelectAutocompleteField,
        AddIndividualMediaButton
    },
    computed: {
        ...identificationTypeComputed,
        genders() {
            return Status.getList('genders');
        },
    },
    validations: {
        customer: {
            identification_type_id: { required },
            identification_media: { required },
            population_id: { required },
            first_name: { required },
            last_name: { required },
            identification: { required },
            birthdate: { required },
            gender: { required },
            postcode: { required },
            address: { required },
            email: { required },
            mobile: { required },
            phone: {  },
        }
    },
    methods: {
        async shown() {
            this.$store.dispatch("identificationType/loadList");

            if (this.id) {
                const response = await api.get({
                    url: `customers/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });

                const customer = response?.data?.data;
                this.customer = this.getCustomerWrapper(customer);
            }
        },
        getCustomerWrapper(customer) {
            return {
                id: customer?.id,
                identification_type_id: customer?.identification_type_id,
                identification_media: customer?.identification_media,
                population_id: customer?.billings[0]?.population_id,
                first_name: customer?.first_name,
                last_name: customer?.last_name,
                identification: customer?.identification,
                birthdate: customer?.birthdate,
                gender: customer?.gender,
                address: customer?.billings[0]?.address_1,
                postcode: customer?.billings[0]?.postcode,
                email: customer?.user?.email,
                mobile: customer?.billings[0]?.phone,
                phone: customer?.billings[0]?.phone_2,
                allow_commercial_mail: customer?.allow_commercial_mail,
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.customer;
            data.identification_media_id = this.customer?.identification_media?.id;
            const response = await api.save({
                url: 'customers',
                id: this.customer?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de cliente'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Datos básicos</h4>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="first_name">Nombre</label>
                                <input id="first_name" v-model="customer.first_name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.customer.first_name.$error && submitted }" />
                                <div v-if="!$v.customer.first_name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="last_name">Apellido</label>
                                <input id="last_name" v-model="customer.last_name" type="text" class="form-control" placeholder="Ingresa un apellido"
                                    :class="{ 'is-invalid': $v.customer.last_name.$error && submitted }" />
                                <div v-if="!$v.customer.last_name.required && submitted" class="invalid-feedback">Apellido es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="birthdate">Fecha de nacimiento</label>
                                <input id="birthdate" v-model="customer.birthdate" type="date" class="form-control"
                                    :class="{ 'is-invalid': $v.customer.birthdate.$error && submitted }" />
                                <div v-if="!$v.customer.birthdate.required && submitted" class="invalid-feedback">Fecha de nacimiento es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="identification_type_id">Tipo de identificación</label>
                                <select v-model="customer.identification_type_id" class="form-control"
                                    :class="{ 'is-invalid': $v.customer.identification_type_id.$error && submitted }">
                                    <option value="">Ninguna</option>
                                    <option v-for="option in identificationTypes" v-bind:value="option.id" :key="option.id">
                                        {{ option.name }}
                                    </option>
                                </select>
                                <div v-if="!$v.customer.identification_type_id.required && submitted" class="invalid-feedback">Tipo de identificación es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="identification">Identificación</label>
                                <input id="identification" v-model="customer.identification" type="text" class="form-control"
                                    :class="{ 'is-invalid': $v.customer.identification.$error && submitted }" />
                                <div v-if="!$v.customer.identification.required && submitted" class="invalid-feedback">Identificación es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="gender">Género</label>
                                <select v-model="customer.gender" class="form-control"
                                    :class="{ 'is-invalid': $v.customer.gender.$error && submitted }">
                                    <option value="">Ninguno</option>
                                    <option v-for="option in genders" v-bind:value="option.id" :key="option.id">
                                        {{ option.label }}
                                    </option>
                                </select>
                                <div v-if="!$v.customer.gender.required && submitted" class="invalid-feedback">Género es requerido</div>
                            </div>
                            <div class="col-12">
                                <label>Adjunto documento de identificación</label>
                                <AddIndividualMediaButton v-model="customer.identification_media"></AddIndividualMediaButton>
                                <div v-if="!$v.customer.identification_media.required && submitted" class="invalid-feedback d-block">Adjunto documento de identificación es requerido</div>
                            </div>
                        </div>                         
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de Dirección
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="address">Dirección</label>
                                <input id="address" v-model="customer.address" type="text" class="form-control" placeholder="Ingresa una dirección"
                                    :class="{ 'is-invalid': $v.customer.address.$error && submitted }" />
                                <div v-if="!$v.customer.address.required && submitted" class="invalid-feedback">Dirección es requerida</div>
                            </div>
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="postcode">Código postal</label>
                                <input id="postcode" v-model="customer.postcode" type="text" class="form-control" placeholder="Ingresa un código postal"
                                    :class="{ 'is-invalid': $v.customer.postcode.$error && submitted }" />
                                <div v-if="!$v.customer.postcode.required && submitted" class="invalid-feedback">Código postal es requerido</div>
                            </div>
                            <div class="form-group col-lg-12">
                                <label>Población</label>
                                <SelectAutocompleteField
                                    :url="`/populations`"
                                    :multiple="false"
                                    :placeholder="'Busca una población'"
                                    :label="'fullname'"
                                    v-model="customer.population_id"
                                    :class="{ 'is-invalid': $v.customer.population_id.$error && submitted }"
                                ></SelectAutocompleteField>
                                <div v-if="!$v.customer.population_id.required && submitted" class="invalid-feedback">Población es requerida</div>
                            </div>
                        </div>                    
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de contacto
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="email">Correo electrónico</label>
                                <input id="email" v-model="customer.email" type="text" class="form-control" placeholder="Ingresa un correo electrónico"
                                    :class="{ 'is-invalid': $v.customer.email.$error && submitted }" />
                                <div v-if="!$v.customer.email.required && submitted" class="invalid-feedback">Correo electrónico</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="mobile">Celular</label>
                                <input id="mobile" v-model="customer.mobile" type="text" class="form-control" placeholder="Ingresa un celular"
                                    :class="{ 'is-invalid': $v.customer.mobile.$error && submitted }" />
                                <div v-if="!$v.customer.mobile.required && submitted" class="invalid-feedback">Celular es requerida</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="phone">Teléfono</label>
                                <input id="phone" v-model="customer.phone" type="text" class="form-control" placeholder="Ingresa un teléfono"
                                    :class="{ 'is-invalid': $v.customer.phone.$error && submitted }" />
                                <div v-if="!$v.customer.phone.required && submitted" class="invalid-feedback">Teléfono es requerido</div>
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>