<script>
import api from '@/helpers/apirest/api';
import Modal from "@/components/modals/modal-form.vue";
import Status from "@/helpers/general/status";
import OrderShow from "@/views/pages/orders/show/show.vue";
import CardApplicationsPartial from "@/views/pages/customers/show/partials/card-applications-partial.vue";
import OrdersPartial from "@/views/pages/customers/show/partials/orders-partial.vue";
import YouthCardsPartial from "@/views/pages/customers/show/partials/youth-cards-partial.vue";
import CustomerForm from "@/views/pages/customers/forms/customer-form.vue";

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        cardApplicationId: Number
    },
    data() {
        return {
            customer:null,
            showOrderModalId:'order-show-modal',
            formModalId:'customer-form-detail-modal',
            customerIntegrationStatus: Status,
            showSelectedItemId:null,
            activeTab:null
        }
    },
    components: {
        Modal,
        OrderShow,
        CardApplicationsPartial,
        OrdersPartial,
        YouthCardsPartial,
        CustomerForm
    },
    computed: {
        modalTilte(){
            return `Detalle del cliente #${(this.customer?.id || '')}`;
        },
        status(){
            return Status.getStatus({itemStatus: this?.customer?.status, type: 'card_application_statuses', html: true});
        },
        gender(){
            return Status.getStatus({itemStatus: this?.customer?.gender, type: 'genders'});
        },
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `customers/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.customer = response?.data?.data;
            }
        },
        shown() {
            this.getData();            
        },
        hidden() {
            this.customer = null;
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        showFormModal() {
            this.$bvModal.show(this.formModalId);
        },
        onSuccess(){            
            this.getData(); 
        },
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" @hidden="hidden" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Cerrar'">
            <OrderShow :modal-id="showOrderModalId" :id="showSelectedItemId"></OrderShow>
            <CustomerForm :modal-id="formModalId" :id="customer?.id" @onSuccess="onSuccess"></CustomerForm>
            <div class="row mb-4">
                <div class="col-xl-3 col-lg-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="row mb-4">
                                <div class="col-12 d-flex align-items-center flex-column justify-content-center">
                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                        <template #loading>
                                            <b-skeleton type="avatar"></b-skeleton>
                                            <b-skeleton width="100%"></b-skeleton>
                                            <b-skeleton width="100%"></b-skeleton>                                            
                                        </template> 

                                        <b-dropdown no-flip right class="qlk-dropdown-sm" style="position: absolute; top: 0px; right: 10px" variant="light" size="sm">
                                            <template v-slot:button-content>
                                                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                                            </template>
                                            <b-dropdown-item @click="showFormModal">Editar</b-dropdown-item>
                                        </b-dropdown>

                                        <div class="avatar-sm"><span class="avatar-title text-uppercase rounded-circle bg-soft-primary text-primary">
                                            {{ customer?.first_name?.charAt(0) + customer?.last_name?.charAt(0) }}
                                        </span></div> 
                                        <h4 class="mt-3 mb-0">{{ customer?.name }}</h4>
                                        <p class="text-muted font-size-14 mb-0">{{ customer?.identification_type?.prefix }}: {{ customer?.identification }}</p>
                                    </b-skeleton-wrapper>
                                </div>
                            </div> 
                            <!-- <hr class="my-3" />  -->
                            <div class="row">
                                <div class="col-12">
                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                        <template #loading>
                                            <b-skeleton-table
                                                :rows="12"
                                                :columns="2"
                                                :hide-header="true"
                                                :table-props="{ bordered: true, striped: true }"
                                            ></b-skeleton-table> 
                                        </template>
                                        <table class="table table-hover mb-0 table-centered table-nowrap">
                                            <tbody>
                                                <tr>
                                                    <th class="text-right">Email</th>
                                                    <td>{{ customer?.user?.email }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Fecha de nacimiento</th>
                                                    <td>{{ customer?.birthdate_formatted }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Género</th>
                                                    <td>{{ gender }}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <th class="text-right">Móvil</th>
                                                    <td>{{ customer?.billings[0]?.phone }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Teléfono</th>
                                                    <td>{{ customer?.billings[0]?.phone_2 }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Adjunto</th>
                                                    <td>
                                                        <a :href="customer?.identification_media?.url" target="_blank" v-if="customer?.identification_media">Descargar</a>
                                                        <span class="text-muted" v-else>Sin adjunto</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th class="text-right">Dirección</th>
                                                    <td>{{ customer?.billings[0]?.address_1 }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Cod. Postal</th>
                                                    <td>{{ customer?.billings[0]?.postcode }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Población</th>
                                                    <td>{{ customer?.billings[0]?.population?.name }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Comarca</th>
                                                    <td>{{ customer?.billings[0]?.population?.region?.name }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Provincia</th>
                                                    <td>{{ customer?.billings[0]?.population?.region?.province?.name }}</td>
                                                </tr>
                                                

                                            </tbody>
                                        </table>
                                    </b-skeleton-wrapper> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-8">
                    <div class="card h-100">
                        <div class="card-body">
                            <b-tabs v-model="activeTab" content-class="p-3 text-muted">
                                <b-tab class="border-0">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">Datos básicos</span>
                                    </template>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="card h-100">
                                                <div class="card-body">                            
                                                    <h4 class="card-title mb-4">Facturación</h4>

                                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                                        <template #loading>
                                                            <b-card>
                                                                <b-skeleton width="75%"></b-skeleton>
                                                                <b-skeleton width="50%"></b-skeleton>
                                                                <b-skeleton width="85%"></b-skeleton>
                                                                <b-skeleton width="45%"></b-skeleton>
                                                                <b-skeleton width="50%"></b-skeleton>
                                                                <b-skeleton width="60%"></b-skeleton>
                                                            </b-card>
                                                        </template>
                                                        <div class="mb-2">
                                                            <h6>{{ customer?.billings[0]?.full_name }}</h6>
                                                            <div v-if="customer?.billings[0]?.company">{{ customer?.billings[0]?.company }}</div>
                                                            <div v-if="customer?.billings[0]?.address_1">{{ customer?.billings[0]?.address_1 }}</div>
                                                            <div v-if="customer?.billings[0]?.address_2">{{ customer?.billings[0]?.address_2 }}</div>
                                                            <div v-if="customer?.billings[0]?.postcode || customer?.billings[0]?.city">{{ customer?.billings[0]?.postcode }} {{ customer?.billings[0]?.city }}</div>
                                                            <div v-if="customer?.billings[0]?.state">{{ customer?.billings[0]?.state }}</div>
                                                            <div v-if="customer?.billings[0]?.country">{{ customer?.billings[0]?.country }}</div>
                                                        </div>
                                                        <div class="mb-2">
                                                            <h6>Dirección de correo electrónico:</h6>
                                                            <a :href="'mailto:' + customer?.billings[0]?.email">{{ customer?.billings[0]?.email }}</a>
                                                        </div>
                                                        <div>
                                                            <h6>Móvil:</h6>
                                                            <a :href="'tel:' + customer?.billings[0]?.mobile">{{ customer?.billings[0]?.mobile }}</a>
                                                        </div>
                                                        <div>
                                                            <h6>Teléfono:</h6>
                                                            <a :href="'tel:' + customer?.billings[0]?.phone">{{ customer?.billings[0]?.phone }}</a>
                                                        </div>
                                                    </b-skeleton-wrapper>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card h-100">
                                                <div class="card-body">                            
                                                    <h4 class="card-title mb-4">Envío</h4>

                                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                                        <template #loading>
                                                            <b-card>
                                                                <b-skeleton width="75%"></b-skeleton>
                                                                <b-skeleton width="50%"></b-skeleton>
                                                                <b-skeleton width="85%"></b-skeleton>
                                                                <b-skeleton width="30%"></b-skeleton>
                                                                <b-skeleton width="65%"></b-skeleton>
                                                            </b-card>
                                                        </template>
                                                        <!-- <div class="mb-2" v-if="order?.order_shipping">
                                                            <div class="mb-2">
                                                                <h6>{{ order?.order_shipping?.full_name }}</h6>
                                                                <div v-if="order?.order_shipping?.company">{{ order?.order_shipping?.company }}</div>
                                                                <div v-if="order?.order_shipping?.address_1">{{ order?.order_shipping?.address_1 }}</div>
                                                                <div v-if="order?.order_shipping?.address_2">{{ order?.order_shipping?.address_2 }}</div>
                                                                <div v-if="order?.order_shipping?.postcode || order?.order_shipping?.city">{{ order?.order_shipping?.postcode }} {{ order?.order_shipping?.city }}</div>
                                                                <div v-if="order?.order_shipping?.state">{{ order?.order_shipping?.state }}</div>
                                                                <div v-if="order?.order_shipping?.country">{{ order?.order_shipping?.country }}</div>
                                                            </div>
                                                            <div>
                                                                <h6>Teléfono:</h6>
                                                                <a :href="'tel:' + order?.order_shipping?.phone">{{ order?.order_shipping?.phone }}</a>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <i>Sin dirección de envío configurada. Se usará la dirección de facturación.</i>
                                                        </div> -->
                                                    </b-skeleton-wrapper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab class="border-0">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">Carné</span>
                                    </template>
                                    <b-card-text>
                                        <YouthCardsPartial :customer="customer"></YouthCardsPartial>
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" :active="(this?.cardApplicationId) ? true : false" lazy>
                                    <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Solicitudes</span>
                                    </template>
                                    <b-card-text>
                                        <CardApplicationsPartial :customerId="id"></CardApplicationsPartial>                                        
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" lazy>
                                    <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Pedidos</span>
                                    </template>
                                    <b-card-text>
                                        <OrdersPartial :customerId="id"></OrdersPartial>
                                    </b-card-text>
                                </b-tab>                              
                            </b-tabs>                      
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>